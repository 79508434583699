import {
  MATERIAL_REQ_LOADED,
  GET_MATERIAL_REQ,
  CREATE_PURCHASE_REQUEST,
  CREATE_PURCHASE_REQUEST_SUCCESS,
  SET_REQUEST_DATA,
  SET_QUO_MAPPED_DOC,
  GET_SUPPLIER_LIST,
  SUPPLIER_LIST_LOADED,
  GET_SUPPLIER_DETAILS,
  SUPPLIER_DETAILS_LOADED,
  CREATE_QUO_REQUEST_SUCCESS,
  CREATE_QUO_REQUEST,
  QUOTATION_REQ_LOADED,
  GET_QUOTATION_REQ,
  SET_QUO_REQ_DETAILS,
  SET_SUPP_QUO_DOC,
  CREATE_SUPPLIER_QUO,
  CREATE_SUPPLIER_QUO_SUCCESS,
  SUPPLIER_QUOTATIONS_LOADED,
  GET_SUPPLIER_QUOTATIONS,
  CREATE_PURCHASE_ORDER_SUCCESS,
  CREATE_PURCHASE_ORDER,
  SET_PURCHASE_MAPPED_DOC,
  GET_PURCHASE_REQ,
  PURCHASE_REQ_LOADED,
  SET_PURCHASE_RECEIPT_MAPPED_DOC,
  CREATE_PURCHASE_RECEIPT_SUCCESS,
  CREATE_PURCHASE_RECEIPT,
  PURCHASE_INVOICE_LOADED,
  GET_PURCHASE_INVOICE,
  PURCHASE_RECEIPT_LOADED,
  GET_PURCHASE_RECEIPT,
  SET_PURCHASE_INVOICE_MAPPED_DOC,
  CREATE_PURCHASE_INVOICE_SUCCESS,
  CREATE_PURCHASE_INVOICE,
  SET_PAYMENT_ENTRY_MAPPED_DOC,
  CREATE_PAYMENT_ENTRY,
  CREATE_PAYMENT_ENTRY_SUCCESS,
  STOCKIST_SIGNATURE,
  SET_PURCHASE_ORDER_DETAILS,
  UPDATE_SIGNATURE_PURCHASE_ORDER,
  UPDATE_SIGNATURE_PURCHASE_ORDER_SUCCESS,
  CREATE_NEW_PURCHASE_ORDER,
  CREATE_NEW_PURCHASE_ORDER_SUCCESS,
  CREATE_NEW_PURCHASE_INVOICE,
  CREATE_NEW_PURCHASE_INVOICE_SUCCESS,
  SET_SELECTED_PURCHASE_ORDER_PACKAGE,
  SET_PURCHASE_ORDER_PACKAGE_DOC,
  SET_PACKAGES_WITH_ITEMS_MATCHING_DBERP,
  GET_AUTO_MAT_REQ,
  AUTO_MAT_REQ_LOADED,
  AUTO_MAT_REQ_DETAILS_LOADED,
} from "./buying.type";

import { FAILED_ADD_DATA } from "../rollback/rollback.type";
import axiosInstance from "../../history/axiosInstance";
import { SetNotification } from "../notification/notification.action";
import { timeout } from "../../helpers/utils";

export const LoadPurchaseRequests = (company) => ({
  type: GET_MATERIAL_REQ,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/transfer-requests`,
        method: "POST",
        body: JSON.stringify({
          doctype: "Material Request",
          fields: [
            "`tabMaterial Request`.`name`",
            "`tabMaterial Request`.`creation`",
            "`tabMaterial Request`.`material_request_type`",
            "`tabMaterial Request`.`status`",
            "`tabMaterial Request`.`schedule_date`",
            "`tabMaterial Request`.`transaction_date`",
            "`tabMaterial Request`.`title`",
            "`tabMaterial Request`.`set_warehouse`",
            "`tabMaterial Request`.`set_from_warehouse`",
          ],
          filters: [
            ["Material Request", "company", "=", `${company}`],
            ["Material Request", "material_request_type", "=", "Purchase"],
          ],
          order_by: "`tabMaterial Request`.`creation` desc",
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: MATERIAL_REQ_LOADED,
        meta: "",
      },
    },
  },
});

export const CreatePurchaseRequest = (content, company) => {
  const action = {
    type: CREATE_PURCHASE_REQUEST,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/transfer-requests/create`,
          method: "POST",
          body: JSON.stringify({
            action: "Save",
            doc: {
              docstatus: 0,
              status: "Draft",
              doctype: "Material Request",
              naming_series: "MAT-MR-.YYYY.-",
              material_request_type: "Purchase",
              transaction_date: new Date().toISOString().slice(0, 10),
              company: `${company}`,
              ...content,
            },
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_PURCHASE_REQUEST_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:purchaseReqCreated",
            },
            then: (data) => (dispatch, getState) => {
              window.location.reload();
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const SetRequestData = (content) => {
  return {
    type: SET_REQUEST_DATA,
    payload: content,
  };
};

export const createQuotationReq = (id) => {
  return async (dispatch) => {
    const data = {
      method:
        "erpnext.stock.doctype.material_request.material_request.make_request_for_quotation",
      source_name: id,
    };
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .post(
        `${process.env.REACT_APP_API_URI}/api/make-mapped-doc`,
        data,
        header
      )
      .then(({ data }) => {
        dispatch(SetQuotationMappedDoc(data.message));
      });
  };
};

export const SetQuotationMappedDoc = (content) => {
  return {
    type: SET_QUO_MAPPED_DOC,
    payload: content,
  };
};

export const LoadSupplierList = () => ({
  type: GET_SUPPLIER_LIST,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/buying/supplier-list`,
        method: "POST",
        body: JSON.stringify({
          doctype: "Supplier",
          fields: ["`tabSupplier`.`name`", "`tabSupplier`.`supplier_name`"],
          filters: [["Supplier", "disabled", "=", 0]],
          order_by: "`tabSupplier`.`creation` desc",
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: SUPPLIER_LIST_LOADED,
        meta: "",
      },
    },
  },
});

export const LoadSupplierDetails = (id) => ({
  type: GET_SUPPLIER_DETAILS,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/party-details`,
        method: "POST",
        body: JSON.stringify({
          party_type: "Supplier",
          party: id,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: SUPPLIER_DETAILS_LOADED,
        meta: "",
      },
    },
  },
});

export const CreateQuotationRequest = (content) => {
  const action = {
    type: CREATE_QUO_REQUEST,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/transfer-requests/create`,
          method: "POST",
          body: JSON.stringify({
            action: "Submit",
            doc: content,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_QUO_REQUEST_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:quoReqCreated",
            },
            then: (data) => (dispatch, getState) => {
              window.location.href = "/buying?tab=1";
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const LoadQuotationRequests = (company) => ({
  type: GET_QUOTATION_REQ,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/transfer-requests`,
        method: "POST",
        body: JSON.stringify({
          doctype: "Request for Quotation",
          fields: [
            "`tabRequest for Quotation`.`name`",
            "`tabRequest for Quotation`.`owner`",
            "`tabRequest for Quotation`.`creation`",
            "`tabRequest for Quotation`.`naming_series`",
            "`tabRequest for Quotation`.`transaction_date`",
            "`tabRequest for Quotation`.`status`",
            "`tabRequest for Quotation`.`message_for_supplier`",
          ],
          filters: [
            ["Request for Quotation", "company", "=", `${company}`],
            ["Request for Quotation", "status", "=", "Submitted"],
          ],
          order_by: "`tabRequest for Quotation`.`creation` desc",
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: QUOTATION_REQ_LOADED,
        meta: "",
      },
    },
  },
});

export const LoadQuoRepById = (id) => {
  return async (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Request+for+Quotation&name=${id}`,
        header
      )
      .then(({ data }) => {
        dispatch(SetQuoReqDetails(data?.docs[0]));
      });
  };
};

export const SetQuoReqDetails = (content) => {
  return {
    type: SET_QUO_REQ_DETAILS,
    payload: content,
  };
};

export const MakeSuppQuotation = (id, supplier) => {
  return async (dispatch) => {
    const data = {
      source_name: id,
      for_supplier: supplier,
    };
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .post(
        `${process.env.REACT_APP_API_URI}/api/buying/make-supplier-quotation`,
        data,
        header
      )
      .then(({ data }) => {
        dispatch(SetSuppQuotationDoc(data?.message));
      });
  };
};

export const SetSuppQuotationDoc = (content) => {
  return {
    type: SET_SUPP_QUO_DOC,
    payload: content,
  };
};

export const CreateSuppQuotation = (content) => {
  const action = {
    type: CREATE_SUPPLIER_QUO,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/transfer-requests/create`,
          method: "POST",
          body: JSON.stringify({
            action: "Submit",
            doc: content,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_SUPPLIER_QUO_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:suppQuoCreated",
            },
            // redirectTo: "/transfer-requests",
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const LoadSupplierQuotations = (company) => ({
  type: GET_SUPPLIER_QUOTATIONS,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/transfer-requests`,
        method: "POST",
        body: JSON.stringify({
          doctype: "Supplier Quotation",
          fields: [
            "`tabSupplier Quotation`.`name`",
            "`tabSupplier Quotation`.`owner`",
            "`tabSupplier Quotation`.`transaction_date`",
            "`tabSupplier Quotation`.`valid_till`",
            "`tabSupplier Quotation`.`total`",
            "`tabSupplier Quotation`.`net_total`",
            "`tabSupplier Quotation`.`taxes_and_charges_added`",
            "`tabSupplier Quotation`.`taxes_and_charges_deducted`",
            "`tabSupplier Quotation`.`total_taxes_and_charges`",
            "`tabSupplier Quotation`.`discount_amount`",
            "`tabSupplier Quotation`.`grand_total`",
            "`tabSupplier Quotation`.`rounding_adjustment`",
            "`tabSupplier Quotation`.`rounded_total`",
            "`tabSupplier Quotation`.`status`",
            "`tabSupplier Quotation`.`title`",
            "`tabSupplier Quotation`.`supplier`",
            "`tabSupplier Quotation`.`base_grand_total`",
            "`tabSupplier Quotation`.`currency`",
          ],
          filters: [["Supplier Quotation", "company", "=", `${company}`]],
          order_by: "`tabSupplier Quotation`.`creation` desc",
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: SUPPLIER_QUOTATIONS_LOADED,
        meta: "",
      },
    },
  },
});

export const CreatePurchaseOrderRequest = (content) => {
  const action = {
    type: CREATE_PURCHASE_ORDER,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/transfer-requests/create`,
          method: "POST",
          body: JSON.stringify({
            action: "Submit",
            doc: content,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_PURCHASE_ORDER_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:purOrderCreated",
            },
            then: (data) => (dispatch, getState) => {
              window.location.href = "/buying?tab=1";
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const SubmitSuppQuoDoc = (id) => {
  return async (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Supplier%20Quotation&name=${id}`,
        header
      )
      .then(async ({ data }) => {
        const header = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        };
        const dataa = JSON.stringify({
          doc: data.docs[0],
          action: "Submit",
        });
        return axiosInstance
          .post(
            `${process.env.REACT_APP_API_URI}/api/transfer-requests/create`,
            dataa,
            header
          )
          .then(({ data }) => {
            window.location.href = "/buying?tab=2";
          });
      });
  };
};

export const LoadPurchaseOrderDoc = (id) => {
  return async (dispatch) => {
    const data = {
      method:
        "erpnext.buying.doctype.supplier_quotation.supplier_quotation.make_purchase_order",
      source_name: id,
    };
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .post(
        `${process.env.REACT_APP_API_URI}/api/make-mapped-doc`,
        data,
        header
      )
      .then(({ data }) => {
        dispatch(SetPurchaseMappedDoc(data.message));
      });
  };
};

export const SetPurchaseMappedDoc = (content) => {
  return {
    type: SET_PURCHASE_MAPPED_DOC,
    payload: content,
  };
};

export const LoadPurchaseOrderRequests = (company) => ({
  type: GET_PURCHASE_REQ,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/transfer-requests`,
        method: "POST",
        body: JSON.stringify({
          doctype: "Purchase Order",
          fields: [
            "`tabPurchase Order`.`name`",
            "`tabPurchase Order`.`owner`",
            "`tabPurchase Order`.`idx`",
            "`tabPurchase Order`.`transaction_date`",
            "`tabPurchase Order`.`total`",
            "`tabPurchase Order`.`net_total`",
            "`tabPurchase Order`.`taxes_and_charges_added`",
            "`tabPurchase Order`.`taxes_and_charges_deducted`",
            "`tabPurchase Order`.`total_taxes_and_charges`",
            "`tabPurchase Order`.`discount_amount`",
            "`tabPurchase Order`.`grand_total`",
            "`tabPurchase Order`.`rounding_adjustment`",
            "`tabPurchase Order`.`rounded_total`",
            "`tabPurchase Order`.`advance_paid`",
            "`tabPurchase Order`.`status`",
            "`tabPurchase Order`.`per_billed`",
            "`tabPurchase Order`.`per_received`",
            "`tabPurchase Order`.`supplier_name`",
            "`tabPurchase Order`.`base_grand_total`",
            "`tabPurchase Order`.`company`",
            "`tabPurchase Order`.`currency`",
            "`tabPurchase Order`.`supplier`",
            "`tabPurchase Order`.`party_account_currency`",
            "`tabPurchase Order`.`signature`",
            "`tabPurchase Order`.`total_qty`",
          ],
          filters: [["Purchase Order", "company", "=", `${company}`]],
          order_by: "`tabPurchase Order`.`modified` desc",
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: PURCHASE_REQ_LOADED,
        meta: "",
      },
    },
  },
});

export const LoadAutoMaterialReq = (company) => ({
  type: GET_AUTO_MAT_REQ,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/get-list`,
        method: "POST",
        body: JSON.stringify({
          doctype: "Material Request",
          fields: [
            "`tabMaterial Request`.`name`",
            "`tabMaterial Request`.`material_request_type`",
            "`tabMaterial Request`.`schedule_date`",
            "`tabMaterial Request`.`set_warehouse`",
            "`tabMaterial Request`.`status`",
            "`tabMaterial Request`.`title`",
          ],
          filters: [
            ["Material Request", "company", "=", `${company}`],
            ["Material Request", "owner", "=", "Administrator"],
          ],
          order_by: "`tabMaterial Request`.`modified` desc",
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: AUTO_MAT_REQ_LOADED,
        meta: "",
      },
    },
  },
});

export const LoadAutoMaterialReqById = (id) => {
  return async (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Material%20Request&name=${id}`,
        header
      )
      .then(({ data }) => {
        dispatch(SetAutoMaterialReqDetails(data?.docs?.[0]));
      });
  };
};

export const SetAutoMaterialReqDetails = (content) => {
  return {
    type: AUTO_MAT_REQ_DETAILS_LOADED,
    payload: content,
  };
};

export const createPurchaseReceiptDoc = (id) => {
  return async (dispatch) => {
    const data = {
      method:
        "erpnext.buying.doctype.purchase_order.purchase_order.make_purchase_receipt",
      source_name: id,
    };
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .post(
        `${process.env.REACT_APP_API_URI}/api/make-mapped-doc`,
        data,
        header
      )
      .then(({ data }) => {
        dispatch(SetPurchaseReceiptMappedDoc(data.message));
      });
  };
};

export const SetPurchaseReceiptMappedDoc = (content) => {
  return {
    type: SET_PURCHASE_RECEIPT_MAPPED_DOC,
    payload: content,
  };
};

export const CreatePurchaseReceiptRequest = (content, id, reqpackage) => {
  const action = {
    type: CREATE_PURCHASE_RECEIPT,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/transfer-requests/create`,
          method: "POST",
          body: JSON.stringify({
            action: "Submit",
            doc: content,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:data?.docs[0]
        commit: {
          type: CREATE_PURCHASE_RECEIPT_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:purRecieptCreated",
            },
            then: (data) => async (dispatch, getState) => {
              if (reqpackage) {
                try {
                  let dataJson = JSON.parse(data);
                  if (dataJson && dataJson?.docs) {
                    let updatereqpackage = reqpackage;

                    if (reqpackage?.purchasereceipt_ids) {
                      updatereqpackage.purchasereceipt_ids =
                        updatereqpackage.purchasereceipt_ids +
                        "," +
                        dataJson?.docs[0]?.name;
                    } else {
                      updatereqpackage.purchasereceipt_ids =
                        dataJson?.docs[0]?.name;
                    }

                    await dispatch(
                      updatePurchaseOrderPackage(
                        updatereqpackage,
                        "buying:successfulPackageUpdate"
                      )
                    );
                  }
                } catch (error) {
                  console.log(error);
                }
              }
              await dispatch(LoadPurchaseReceipt(content?.company));
              if (id) {
                await dispatch(LoadPurchaseOrderById(id));
                // window.location.reload();
              }
              !id && (window.location.href = "/buying?tab=2");
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const LoadPurchaseReceipt = (company) => ({
  type: GET_PURCHASE_RECEIPT,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/transfer-requests`,
        method: "POST",
        body: JSON.stringify({
          doctype: "Purchase Receipt",
          fields: [
            "`tabPurchase Receipt`.`name`",
            "`tabPurchase Receipt`.`owner`",
            "`tabPurchase Receipt`.`posting_date`",
            "`tabPurchase Receipt`.`total`",
            "`tabPurchase Receipt`.`net_total`",
            "`tabPurchase Receipt`.`taxes_and_charges_added`",
            "`tabPurchase Receipt`.`taxes_and_charges_deducted`",
            "`tabPurchase Receipt`.`total_taxes_and_charges`",
            "`tabPurchase Receipt`.`discount_amount`",
            "`tabPurchase Receipt`.`grand_total`",
            "`tabPurchase Receipt`.`rounding_adjustment`",
            "`tabPurchase Receipt`.`rounded_total`",
            "`tabPurchase Receipt`.`status`",
            "`tabPurchase Receipt`.`title`",
            "`tabPurchase Receipt`.`supplier`",
            "`tabPurchase Receipt`.`supplier_name`",
            "`tabPurchase Receipt`.`base_grand_total`",
            "`tabPurchase Receipt`.`is_subcontracted`",
            "`tabPurchase Receipt`.`transporter_name`",
            "`tabPurchase Receipt`.`is_return`",
            "`tabPurchase Receipt`.`per_billed`",
            "`tabPurchase Receipt`.`currency`",
          ],
          filters: [
            company ? ["Purchase Receipt", "company", "=", `${company}`] : [],
          ],
          order_by: "`tabPurchase Receipt`.`creation` desc",
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: PURCHASE_RECEIPT_LOADED,
        meta: "",
      },
    },
  },
});

export const createPurchaseInvoiceDoc = (id) => {
  return async (dispatch) => {
    const data = {
      method:
        "erpnext.stock.doctype.purchase_receipt.purchase_receipt.make_purchase_invoice",
      source_name: id,
    };
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .post(
        `${process.env.REACT_APP_API_URI}/api/make-mapped-doc`,
        data,
        header
      )
      .then(({ data }) => {
        dispatch(SetPurchaseInvoiceMappedDoc(data.message));
      });
  };
};

export const createPurchaseInvoiceDocFromPO = (id) => {
  return async (dispatch) => {
    const data = {
      method:
        "erpnext.buying.doctype.purchase_order.purchase_order.make_purchase_invoice",
      source_name: id,
    };
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .post(
        `${process.env.REACT_APP_API_URI}/api/make-mapped-doc`,
        data,
        header
      )
      .then(({ data }) => {
        dispatch(SetPurchaseInvoiceMappedDoc(data.message));
      });
  };
};

export const SetPurchaseInvoiceMappedDoc = (content) => {
  return {
    type: SET_PURCHASE_INVOICE_MAPPED_DOC,
    payload: content,
  };
};

export const CreatePurchaseInvoiceRequest = (content) => {
  const action = {
    type: CREATE_PURCHASE_INVOICE,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/transfer-requests/create`,
          method: "POST",
          body: JSON.stringify({
            action: "Submit",
            doc: content,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_PURCHASE_INVOICE_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:purInvoiceCreated",
            },
            then: (data) => async (dispatch, getState) => {
              await timeout(3000);
              window.location.href = "/buying?tab=3";
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const LoadPurchaseInvoice = (company) => ({
  type: GET_PURCHASE_INVOICE,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/transfer-requests`,
        method: "POST",
        body: JSON.stringify({
          doctype: "Purchase Invoice",
          fields: [
            "`tabPurchase Invoice`.`name`",
            "`tabPurchase Invoice`.`owner`",
            "`tabPurchase Invoice`.`posting_date`",
            "`tabPurchase Invoice`.`total`",
            "`tabPurchase Invoice`.`net_total`",
            "`tabPurchase Invoice`.`taxes_and_charges_added`",
            "`tabPurchase Invoice`.`taxes_and_charges_deducted`",
            "`tabPurchase Invoice`.`total_taxes_and_charges`",
            "`tabPurchase Invoice`.`discount_amount`",
            "`tabPurchase Invoice`.`grand_total`",
            "`tabPurchase Invoice`.`rounding_adjustment`",
            "`tabPurchase Invoice`.`rounded_total`",
            "`tabPurchase Invoice`.`total_advance`",
            "`tabPurchase Invoice`.`outstanding_amount`",
            "`tabPurchase Invoice`.`paid_amount`",
            "`tabPurchase Invoice`.`write_off_amount`",
            "`tabPurchase Invoice`.`status`",
            "`tabPurchase Invoice`.`title`",
            "`tabPurchase Invoice`.`supplier`",
            "`tabPurchase Invoice`.`supplier_name`",
            "`tabPurchase Invoice`.`base_grand_total`",
            "`tabPurchase Invoice`.`due_date`",
            "`tabPurchase Invoice`.`company`",
            "`tabPurchase Invoice`.`currency`",
            "`tabPurchase Invoice`.`is_return`",
            "`tabPurchase Invoice`.`release_date`",
            "`tabPurchase Invoice`.`on_hold`",
            "`tabPurchase Invoice`.`represents_company`",
            "`tabPurchase Invoice`.`is_internal_supplier`",
            "`tabPurchase Invoice`.`party_account_currency`",
          ],
          filters: [["Purchase Invoice", "company", "=", `${company}`]],
          order_by: "`tabPurchase Invoice`.`creation` desc",
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: PURCHASE_INVOICE_LOADED,
        meta: "",
      },
    },
  },
});

export const createPaymentEntryeDoc = (id) => {
  return async (dispatch) => {
    const data = {
      dt: "Purchase Invoice",
      dn: id,
    };
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .post(
        `${process.env.REACT_APP_API_URI}/api/buying/get-payment-entry-doc`,
        data,
        header
      )
      .then(({ data }) => {
        dispatch(SetPaymentEntryMappedDoc(data.message));
      });
  };
};

export const SetPaymentEntryMappedDoc = (content) => {
  return {
    type: SET_PAYMENT_ENTRY_MAPPED_DOC,
    payload: content,
  };
};

export const CreatePaymentEntryRequest = (content) => {
  const action = {
    type: CREATE_PAYMENT_ENTRY,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/document/payment-entry`,
          method: "POST",
          body: JSON.stringify({
            action: "Submit",
            doc: content,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_PAYMENT_ENTRY_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:paymentCreated",
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const SetStockistSignature = (stockistSignature) => {
  return {
    type: STOCKIST_SIGNATURE,
    stockistSignature: stockistSignature,
  };
};

export const LoadPurchaseOrderById = (id) => {
  return async (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Purchase+Order&name=${id}`,
        header
      )
      .then(({ data }) => {
        dispatch(SetPurchaseOrderDetails(data?.docs[0]));
      });
  };
};

export const SetPurchaseOrderDetails = (content) => {
  return {
    type: SET_PURCHASE_ORDER_DETAILS,
    payload: content,
  };
};

export const UpdateSignaturePurchaseOrder = (content) => {
  const action = {
    type: UPDATE_SIGNATURE_PURCHASE_ORDER,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/pos/update`,
          method: "POST",
          body: JSON.stringify({
            doctype: "Purchase Order",
            action: "update",
            docnames: [content.name],
            data: {
              signature: content.signature,
            },
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: UPDATE_SIGNATURE_PURCHASE_ORDER_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:purOrderSigned",
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const createPurchaseOrderRequests = (content) => ({
  type: CREATE_NEW_PURCHASE_ORDER,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/save-docs`,
        method: "POST",
        body: JSON.stringify({
          doc: content,
          action: "Submit",
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_NEW_PURCHASE_ORDER_SUCCESS,
        meta: {
          notification: {
            code: "success",
            message: "notification:successCreatedPurchaseOrder",
          },
          then: (data) => async (dispatch, getState) => {
            await timeout(3000);
            window.location.href = "/buying?tab=1";
          },
        },
      },
    },
  },
});
export const createPurchaseInvoiceRequests = (content) => ({
  type: CREATE_NEW_PURCHASE_INVOICE,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/save-docs`,
        method: "POST",
        body: JSON.stringify({
          doc: content,
          action: "Submit",
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_NEW_PURCHASE_INVOICE_SUCCESS,
        meta: {
          notification: {
            code: "success",
            message: "notification:successCreatedPurchaseInvoice",
          },
        },
      },
    },
  },
});

export const LoadPurchaseOrderPackageById = (id) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/get-by-purchase-order/${id}`,
        header
      )
      .then(async ({ data }) => {
        await dispatch(updateSelectedPurchaseOrderPackage(data));
      });
  };
};

export function updateSelectedPurchaseOrderPackage(purchaseOrderPackage) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_SELECTED_PURCHASE_ORDER_PACKAGE,
      payload: purchaseOrderPackage,
    });
  };
}

export const updatePurchaseOrderPackage = (content, msg) => {
  return async (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .post(
        `${process.env.REACT_APP_API_URI}/api/purchase-package/update`,
        content,
        header
      )
      .then(({ data }) => {
        dispatch(SetPurchaseOrderPackageDoc(data.message));
        if (msg)
          dispatch(
            SetNotification({
              code: "success",
              message: msg,
            })
          );
      })
      .catch((err) => {
        dispatch(
          SetNotification({
            code: "error",
            message: err?.message,
          })
        );
      });
  };
};
export const SetPurchaseOrderPackageDoc = (content) => {
  return {
    type: SET_PURCHASE_ORDER_PACKAGE_DOC,
    payload: content,
  };
};

export const SetpackagesWithItemsDbErpData = (content) => {
  return {
    type: SET_PACKAGES_WITH_ITEMS_MATCHING_DBERP,
    payload: content,
  };
};
